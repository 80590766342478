<template>
  <div>
    <b-navbar>
      <template slot="brand">
        <b-navbar-item tag="router-link" :to="{ path: '/forum' }">
          论坛
        </b-navbar-item>
      </template>
      <template slot="start">
        <b-navbar-item tag="router-link" :to="{ path: '/AllGallery' }">
          画廊
        </b-navbar-item>
        <b-navbar-dropdown label="Info">
          <b-navbar-item href="#"> About </b-navbar-item>
          <b-navbar-item href="#"> Contact </b-navbar-item>
        </b-navbar-dropdown>
      </template>
    </b-navbar>
    <div style="padding: 50px">
      <div class="columns">
        <div class="column is-four-fifths">
          <b-carousel
            :autoplay="false"
            with-carousel-list
            :indicator="false"
            :overlay="gallery"
            @click="switchGallery(true)"
          >
            <b-carousel-item v-for="(item, i) in images" :key="i">
              <figure class="image">
                <img v-lazy="item.image" />
              </figure>
            </b-carousel-item>
            <span
              v-if="gallery"
              @click="switchGallery(false)"
              class="modal-close is-large"
            />
            <template slot="list" slot-scope="props">
              <b-carousel-list
                v-model="props.active"
                :data="images"
                v-bind="al"
                @switch="props.switch($event, false)"
                as-indicator
              />
            </template>
          </b-carousel>
        </div>
        <div class="column">
          <div class="card" v-if="desc != null">
            <header class="card-header">
              <p class="card-header-title">
                {{ desc.raw.title }}
              </p>
            </header>
            <div class="card-content">
              <div class="content">
                {{ desc.raw.text }}
                <br />
                <time datetime="2016-1-1">{{ desc.raw.date }}</time>
              </div>
            </div>
            <footer class="card-footer">
              <a class="card-footer-item">
                <b-tooltip label="好活" type="is-light" position="is-top">
                  <template>👍</template>
                </b-tooltip>
              </a>

              <a class="card-footer-item">
                <b-tooltip label="出警" type="is-light" position="is-top">
                  <template>🚔</template>
                </b-tooltip>
              </a>
            </footer>
          </div>
        </div>
      </div>
      <b-button
        type="is-warning"
        :rounded="true"
        icon-left="chevron-up"
        @click="GoUp"
        style="position: fixed; bottom: 100px; right: 50px; z-index: 2000"
      ></b-button>
      <b-button
        type="is-warning"
        :rounded="true"
        icon-left="chevron-down"
        @click="GoDown"
        style="position: fixed; bottom: 50px; right: 50px; z-index: 2000"
      ></b-button>
    </div>
    <b-modal v-model="isCardModalActive" :width="640" scroll="keep">
      <div class="modal-card" style="width: auto">
        <header class="modal-card-head">
          <p class="modal-card-title">受限制页面</p>
          <button type="button" class="delete" @click="$emit('close')" />
        </header>
        <section class="modal-card-body">
          <b-field label="访问密钥">
            <b-input type="text" v-model="token" placeholder="token" required>
            </b-input>
          </b-field>
        </section>
        <footer class="modal-card-foot">
          <button class="button" type="button" @click="$emit('close')">
            Close
          </button>
          <button class="button is-primary" @click="tokenSubmit">提交</button>
        </footer>
      </div>
    </b-modal>
  </div>
</template>

<script>
export default {
  data() {
    return {
      desc: null,
      gallery: false,
      al: {
        hasGrayscale: true,
        itemsToShow: 2,
        breakpoints: {
          768: {
            hasGrayscale: false,
            itemsToShow: 4,
          },
          960: {
            hasGrayscale: true,
            itemsToShow: 6,
          },
        },
      },
      gid: "",
      images: [],
      token: "",
      isCardModalActive: false,
    };
  },
  methods: {
    async tokenSubmit() {
      let res = await this.$http.post(
        "/api/gallery/view/" + this.$route.query.gid,
        {
          token: this.token,
        }
      );
      if (res.data.status == 20000) {
        this.images = res.data.raw.images;
        this.desc = res.data;
        this.isCardModalActive = false
      } else {
        return this.$buefy.toast.open("请检查该密钥是否有权限访问");
      }
    },
    GoDown() {
      window.scrollTo(0, document.documentElement.clientHeight * 2);
    },
    GoUp() {
      let nowTop =
        document.body.scrollTop || document.documentElement.scrollTop; // 获取当前滚动条位置
      if (nowTop > 0) {
        window.requestAnimationFrame(this.GoUp);
        window.scrollTo(0, nowTop - nowTop / 50);
      }
    },
    switchGallery(value) {
      this.gallery = value;
      if (value) {
        document.documentElement.classList.add("is-clipped");
      } else {
        document.documentElement.classList.remove("is-clipped");
      }
    },
  },
  async beforeCreate() {
    //页号问题，少了一页
    this.gid = this.$route.query.gid;
    if ((await localStorage.getItem("token")) == null) {
      return (this.isCardModalActive = true);
    }
    try {
      let res = await this.$http.get(
        "/api/gallery/view/" + this.$route.query.gid,
        {
          headers: {
            pass: "",
            Authorization: "Bearer " + (await localStorage.getItem("token")),
          },
        }
      );
      this.images = res.data.raw.images;
      this.desc = res.data;
    } catch (error) {
      console.log(error);
      return (this.isCardModalActive = true);
    }
  },
};
</script>

<style>
</style>
